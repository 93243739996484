import ApiService from "@/core/services/api.service";
import router from "@/router";
import store from '@/core/services/store';
import { SPAM_WORKFLOW } from "@/core/services/store/modules/workflow";

export default {
    namespaced: true,

    state() {
        return {
            newMails: [],
            totalNewMails: 0,
            filteredNewMails: [],
            isFilter: false, 
            processMails: [],
            totalProcessMails: 0,
            filteredProcessMails: [],
            pendingMails: [],
            totalPendingMails: 0,
            filteredPendingMails: [],
            finalizedMails: [],
            totalFinalizedMails: 0,
            filteredfinalizedMails: [], 
            mailsInSpam: [],
            modal: false,
            filterU: 'all',
            departUsers: [],
            selectedMails: [],
            assigneeMail: {
                "conversations_ids": [],
                "user_id": null,
                "mailbox_id": null
            },
            assigneeProcessMail: {
                "conversations_ids": '',
                "user_id": null,
            },
            spamMail: {
                "conversations_ids": [],
                "status": "spam"
            },
            conversation: {},
            conversations: [], 
            inputSearchValue: '',
            searchEmails: [],
            loadNewMails: false, 
            loadInProcessMails: false, 
            loadPendingMails: false, 
            loadFinalizedMails: false, 
            resetSelected: false,
            config: {},
            savedReplies: [], 
            workflows: [], 
            ordersByTel: [],
            originalConversation: '', 
            forwardMail: {}, 
        }
    },

    getters: {
        newMails: state => {
            if (state.isFilter) {
                return state.filteredNewMails;
            }
            return state.newMails;
        },
        totalNewMails: state => {
            return state.totalNewMails
        },
        loadNewMails: state => {
            return state.loadNewMails
        },
        isFilter: state => {
            return state.isFilter;
        }, 
        processMails: state => {
            if (state.isFilter) {
                return state.filteredProcessMails;
            }
            return state.processMails;
        },
        totalProcessMails: state => {
            return state.totalProcessMails
        },
        loadInProcessMails: state => {
            return state.loadInProcessMails
        },
        pendingMails: state => {
            if (state.isFilter) {
                return state.filteredPendingMails;
            }
            return state.pendingMails;
        },
        totalPendingMails: state => {
            return state.totalPendingMails
        },
        loadPendingMails: state => {
            return state.loadPendingMails
        },
        finalizedMails: state => {
            if (state.isFilter) {
                return state.filteredfinalizedMails;
            }
            return state.finalizedMails;
        },
        totalFinalizedMails: state => {
            return state.totalFinalizedMails;
        },
        loadFinalizedMails: state => {
            return state.loadFinalizedMails
        },
        mailsInSpam: state => {
            return state.mailsInSpam;
        },
        selectedMails: state => {
            return state.selectedMails;
        },
        modal: state => {
            return state.modal;
        },
        filterU: state => {
            return state.filterU;
        },
        departUsers: state => {
            return state.departUsers;
        },
        assigneeMail: state => {
            return state.assigneeMail;
        },
        assigneeProcessMail: state => {
            return state.assigneeProcessMail;
        },
        spamMail: state => {
            return state.spamMail;
        },
        conversation: state => {
            return state.conversation;
        }, 
        inputSearchValue: state => {
            return state.inputSearchValue;
        }, 
        searchEmails: state => {
            return state.searchEmails
        },
        resetSelected: state => {
            return state.resetSelected;
        }, 
        config: state => {
            return state.config;
        }, 
        conversations: state => {
            return state.conversations;
        },
        savedReplies: state => {
            return state.savedReplies;
        },
        workflows: state => {
            return state.workflows;
        },
        ordersByTel: state => {
            return state.ordersByTel;
        }, 
        originalConversation: state => {
            return state.originalConversation;
        },
        forwardMail: state => {
            return state.forwardMail;
        }
    },

    actions: {

        async getNewMails({ commit }, payload = null) {
            let mailboxs = '';
            if (payload && payload.mailboxId) {
                mailboxs = '&mailboxId='+payload.mailboxId;
            }
            // console.log('get new mails');
            return await ApiService.get(`conversations?status=active${mailboxs}`)
            .then((response) => {
                commit('setNewMails', response);
                return response;
            })
        },

        async getEmails({commit}, payload ) {
            let payloadString = '?';
            for (const value in payload) {
                if (payloadString !== '?') {
                    payloadString = payloadString + '&'+ `${value}` + '='+`${payload[value]}`;
                } else {
                    payloadString = payloadString + `${value}` + '='+`${payload[value]}`;
                }
            }

           return await ApiService.get(`conversations${payloadString}`)
            .then((response) => {
                return response._embedded.conversations;
                // commit('setProcessMails', response._embedded.conversations)
            })

        },

        async getProcessMails({ commit }) {
            await ApiService.get('conversations?status=open')
                .then((response) => {
                    // console.log('payload din ProcessMail', payload);
                    // console.log('response din process mails', response);
                    commit('setProcessMails', response)
                })
                // .catch((error) => {console.log(error)});
        },

        async getPendingMails({ commit }) {
            await ApiService.get('conversations?status=pending')
                .then((response) => {
                    commit('setPendingMails', response)
                })
                // .catch((error) => {console.log(error)});
        },

        async getFinalizedMails({ commit }) {
            await ApiService.get('conversations?status=closed&sortField=updatedAt&sortOrder=desc')
                .then((response) => {
                    // console.log(response);
                    commit('setFinalizedMails', response)
                })
                // .catch((error) => {console.log(error)});
        },

        async getMailsInSpam({ commit }) {
            await ApiService.get('conversations?status=spam&embed=threads')
                .then((response) => {
                    // console.log(response._embedded.conversations);
                    commit('setMailsInSpam', response._embedded.conversations)
                })
                // .catch((error) => {console.log(error)});
        },
        // async getSpamMails({ commit }, payload) {
        //     let payloadString = '';
        //     for (const value in payload) {
        //         payloadString = payloadString + '&' + `${value}` + '=' + `${payload[value]}`;
        //     }
        //     await ApiService.get(`conversations?status=spam${payloadString}`)
        //         .then((response) => {
        //             // console.log(response._embedded.conversations);
        //             commit('setMailsInSpam', response._embedded.conversations)
        //         })
        //         // .catch((error) => {console.log(error)});
        // },
        getAllConversations({ commit }) {
            ApiService.get('conversations')
            .then((response) => {
                commit('setAllConversations', response._embedded.conversations);
            })
        },

        // get conversations by mailbox, user
        async getConversations({ commit, state }, payload) {
            state.loadNewMails = false;
            state.loadInProcessMails= false, 
            state.loadPendingMails = false;
            state.loadFinalizedMails = false;
            let payloadString = '';
            var curentUser = store.getters["user"];
            var myDepartaments = store.getters["departments/departments"];
            for (const value in payload) {
                payloadString = payloadString + '&'+ `${value}` + '='+`${payload[value]}`;
            }

            let mailboxs = '';
            if (payload.mailboxId) {
                mailboxs = '&mailboxId='+payload.mailboxId;
            } else {
                let departs_ids = "";
                for (const depart in myDepartaments) {
                    departs_ids =
                      departs_ids + (depart > 0 ? "," : "") + myDepartaments[depart].id;
                  }
                  mailboxs = '&mailboxId='+departs_ids; 
            }

            ApiService.get(`conversations?status=active${mailboxs}`)
            .then((response) => {
                let conversation = response._embedded.conversations
                if(conversation.length > 0){

                    conversation = conversation.filter(item => {
                            var isManager = myDepartaments.filter(depart_item => {
                                return depart_item.isManager && depart_item.id == item.mailboxId;
                            });
                            return item.assignee == null || (item.assignee != null && item.assignee.id == curentUser.id) || isManager.length > 0 || curentUser.tyle == "admin";
                        });
                }


                conversation = conversation.sort(function (a, b) {
                    var a_count = a.id;
                    var b_count = b.id;
                    if(a.assignee && a.assignee.id == curentUser.id){
                        a_count = a_count-(a_count*10000)
                    } 
                    if(b.assignee && b.assignee.id == curentUser.id){
                        b_count = b_count-(b_count*10000)
                    }

                    return a_count-b_count;
                })
                response._embedded.conversations = conversation;
                commit('setNewMails', response)        
            })
            // .catch((error) => {console.log(error)});
            ApiService.get('conversations?status=open,pending&pendingDate=today&sortOrder=asc&sortField=status' + payloadString)
                .then((response) => {
                    // console.log('response din process mails1', response);
                    commit('setProcessMails', response)
                })
                // .catch((error) => {console.log(error)});
            ApiService.get('conversations?status=pending&pendingDate=tommorow' + payloadString)
                .then((response) => {
                    // console.log('response din pending mails', response);
                    commit('setPendingMails', response)
                })
                // .catch((error) => {console.log(error)});
            ApiService.get('conversations?status=closed&sortField=updatedAt&sortOrder=desc&per_page=50' + payloadString)
                .then((response) => {
                    commit('setFinalizedMails', response)
                })
                // .catch((error) => {console.log(error)});
        },

        async clearConversations({ commit }) {
            await commit('clearConversations');
        },

        getConversation({ commit }, id) {
            return ApiService.get('conversations/' + id)
            .then((response) => {
                commit('setConversation', response)
                return response
            })
            // .catch((error) => {console.log(error)});
        },

        getBounceConversation({ commit }, id) {
            return ApiService.get('conversations/' + id)
            .then((response) => {
                return response;
            })
            // .catch((error) => {console.log(error)});
        },

        getDepartUsers({ commit }, users) {
            commit('setDepartUsers', users)
        },

        getUserName({ commit }, name) {
            commit('setUserName', name)
        },

        closeModal({ commit, state }) {
            if (state.modal === true) {
                commit('setModalClose');
            }
        },
        openModal({ commit, state }) {
            if (state.modal === false) {
                commit('setModalOpen');
            }
        },
        asigneeDepUser({ commit }, { departId, userId }) {
            commit('setAssigneeMail', { departId, userId });
        },
        asigneeProcessIds({ commit }, { selectedMail, currentUser }) {
            commit('setAssigneeProcessMail', { selectedMail, currentUser } );
        },
        assigneeMail({ state, dispatch }, params) {
            let payload = {
                conversations_ids: state.selectedMails,
                mailbox_id: params.departId,
                number: params.number
            };
            
            if (params.userId != null) {
                payload.user_id = params.userId;
            }             
            else if (state.conversation.mailboxId === state.assigneeMail.mailbox_id) {
                payload.user_id = -1;
            }

            if (params.tags != null) {
                payload.tags = params.tags;
            }

            ApiService.post('conversations/assigne', payload).then(() => {
                // astept raspuns

                store.commit('alerts/setNewAlert', {
                    message_header: 'Pasare',
                    message_content: "Ati pasat cu succes conversatia #" + payload.number
                });
                dispatch('getConversation', state.selectedMails);
                dispatch('refreshMails');
            })
        },
        assigneeMailId({ commit }, convId) {
            commit('setAssigneeMailId', convId);
        },
        assigneeProcessMail({ state, dispatch }, payload) {
            ApiService.post('conversations/assigne', payload).then(() => {
                dispatch('getConversation', state.conversation.id);
            })
        },
        getSpamMailId({ commit }, convId) {
            commit('setSpamMailId', convId);
        },
        redirect() {

            var curentUser = store.getters["user"];
            router.push({ name: "user", params: { user: curentUser.id } });
            store.commit("alerts/setNewAlert", {
                message_header: "Acces pagina",
                message_content: "Nu ai permisiunea sa accesezi acest mail.",
                variant: "danger",
            });
        },
        hasAccessToConv({dispatch}, conversation) {

            var curentUser = store.getters["user"];
            store.dispatch("departments/getDepartments").then((departments) => {
                let depart = departments.find((dep) => dep.id === conversation.mailboxId);
                if (conversation.is_following) {
                    return;
                }
                if (conversation.status == "spam") {
                    return;
                }
                // nu e departamentul lui face redirect
                if (!depart) {
                    // console.log("fa redirect 1");
                    dispatch("redirect");
                }

                // nu e conversatia lui si nu e manager pe departament face redicer
                else if (
                    conversation.assignee?.id !== curentUser.id &&
                    !depart.isManager &&
                    conversation.status !== "active"
                ) {
                    dispatch("redirect");
                }
            });
        },
        spamMail({ state, dispatch, commit }, payload = null) {
            let spamData = {
                "conversations_ids": state.selectedMails,
            };
            
            if (payload) {
                spamData.all_mailboxes = payload.addRuleForAll;
            }

            return ApiService.post('multi-spam', spamData).then((response) => {
                commit('setResetSelected', true);
                dispatch('refreshMails');
                return response;
            })

            // return store.dispatch(SPAM_WORKFLOW, spamData).then((response) => {
            //     // console.log(response);
            //     ApiService.post('conversations/updatestatus', spamData)
            //     .then((response) => {
            //         if(response.msg && response.msg == 'Status updated'){

            //             store.commit("alerts/setNewAlert", {
            //                 message_header: "Status conversatie modificat",
            //                 message_content: "Conversatiea a fost adaugata in SPAM",
            //                 variant: "info",
            //             });
            //         }
            //     })
            //     .then(() => {
            //         commit('setResetSelected', true);
            //         dispatch('refreshMails');
            //             if(spamData.conversations_ids.length == 1){
            //                 dispatch("getConversation", spamData.conversations_ids[0]).then((conversation) => {
            //                         dispatch("hasAccessToConv",  conversation);
            //                 });
            //             }
            //     })
            // });
        }, 
        refreshMails({ commit, dispatch}) {
            let payload = {}

            if (router.history.current.params.department) {
                payload.mailboxId = +router.history.current.params.department;
            }
        
            if (router.history.current.params.user) {
                payload.assignedTo = +router.history.current.params.user;
            }
          
            commit('setResetSelected', true);
            dispatch("getConversations", payload);
        },
        search({ state, commit, dispatch}, word) {

            commit('setInputSearchValue', word);

            let resultsNewMails = state.newMails.filter(item => {
                return item.customer.email.includes(word) || 
                    item.subject.includes(word) || 
                    String(item.number).includes(word) 
                    || (item.pendingStatus ? item.pendingStatus : '').includes(word);
            })
            commit('setFilteredNewMails', resultsNewMails);
            let resultsfilteredProcessMails = state.processMails.filter(item => {
                return item.customer.email.includes(word) || 
                    item.subject.includes(word) || 
                    String(item.number).includes(word) 
                    || (item.pendingStatus ? item.pendingStatus : '').includes(word);
            })
            commit('setFilteredProcessMails', resultsfilteredProcessMails);
            let resultsfilteredPendingMails = state.pendingMails.filter(item => {
                return item.customer.email.includes(word) || 
                    item.subject.includes(word) || 
                    String(item.number).includes(word) 
                    || (item.pendingStatus ? item.pendingStatus : '').includes(word);
            })
            commit('setFilteredPendingMails', resultsfilteredPendingMails);
            let resultsfilteredFinalizedMails = state.finalizedMails.filter(item => {
                return item.customer.email.includes(word) || 
                    item.subject.includes(word) || 
                    String(item.number).includes(word) 
                    || (item.pendingStatus ? item.pendingStatus : '').includes(word);
            })
            commit('setFilteredFinalizedMails', resultsfilteredFinalizedMails);
        },
        filterMails ({ commit }, payload) {
            commit('setIsFilter', payload);
        }, 
        inputSearch( { commit }, payload) {
            commit('setInputSearchValue', payload);
        }, 

        replyThread({ dispatch }, payload) {
            return ApiService.post(`conversations/${payload.id}/threads`, payload.mailData).then((response) => {
                dispatch('getConversation', payload.id);
                return  {
                    "message_header": "Mesaj trimis cu success!",
                    "notification_date": response.createdAt,
                    "message_content": response.action.text + '<br/>' + response.preview
                }
            })
        },

        // crate conversation
        createConversation({ dispatch }, payload) {
            // console.log(payload);
            return ApiService.post('conversations', payload).then((response) => {
                router.push({ name: "conversation", params: { id: response.id } });
                return response;
            })
        }, 
        
        advancedSearch({ commit }, payload) {

                // let ct = 0;
                // let payloadString = '';
                // for (const value in payload) {
                //     // ct++;
                //     // let sign = ct === 1 ? '?' : '&';
                //     payloadString = payloadString + '&' + `${value}` + '=' + `${payload[value]}`;
                // }

                let payloadString = '?';
                for (const value in payload) {
                    if (payloadString !== '?') {
                        payloadString = payloadString + '&'+ `${value}` + '='+`${payload[value]}`;
                    } else {
                        payloadString = payloadString + `${value}` + '='+`${payload[value]}`;
                    }
                }
            return ApiService.get(`search/conversations${payloadString}`).then((response) => {
                commit('setSearchAdvancedMails', response.conversations);
                return response;
            })
        },
        resetAdvancedSearch({ commit }) {
            commit('setSearchAdvancedMails', []);
        }, 
        confirmMail({state, dispatch}, payload) {
            ApiService.put(`conversations/${state.conversation.id}`, payload).then(() => {
                dispatch('getConversation', state.conversation.id)
            });
        }, 
        resetSelected({commit}, payload) {
            commit('setResetSelected', payload);
        },
        resetConversation({commit}) {
            commit('setConversation', {});
        }, 
        undoSpamStatus({ dispatch }, payload) {
            // console.log(payload);
            ApiService.post('conversations/updatestatus', {
                conversations_ids: payload.id,
                status: "active"
            }).then(() => {
                dispatch('getMailsInSpam');
            })
        },
        getConfig({ commit }) {
            ApiService.get('configs').then((response) => {
                commit('setConfig', response._embedded)
            })
        }, 
        changeTag({ state, dispatch }, payload) {
            ApiService.put(`conversations/${payload.conversation_id}/tags`, payload.tags).then(() => {
                // console.log(router.history.current);
                if (router.history.current.params.id) {
                    dispatch('getConversation', payload.conversation_id);
                }
                else {
                    // console.log('mai multe');
                    dispatch('refreshMails');
                    // const elem = state.conversations.find((el, idx) => 
                    // { 
                    //     if(el.id === payload.conversation_id) {
                    //         state.conversations[idx]._embedded.tags[0].color = payload.color
                    //     };
                    //     return el.id === payload.conversation_id
                    // });
                }
            })
        },
        searchCustomers({}, payload) {
            return ApiService.get(`customers-search?keyword=${payload}`).then(response => {
                return response.customers
            })
        },
        followConversation({ state, dispatch }, payload) {
            ApiService.post('conversations/follow', payload).then(() => {
                dispatch('getConversation', state.conversation.id);
            })
        }, 
        getSavedReplies({ commit }, payload) {
            ApiService.get(`mailbox/saved-replies/${payload.id}`).then((response) => {
                commit('setSavedReplies', response.saved_replies);
            })
        }, 
        createSavedReply({}, payload) {
            ApiService.post('mailbox/create-saved-reply', payload).then((response) => {
                // console.log(response);
            })           
        }, 
        updateSavedReply({dispatch}, payload) {
            ApiService.put('mailbox/update-saved-reply', payload).then((response) => {
                dispatch('getSavedReplies', { id: payload.mailbox_id});
            }) 
        }, 
        deleteSavedReply({ dispatch }, payload) {
            ApiService.delete(`mailbox/delete-saved-reply/${payload.saved_reply_id}`).then(() => {
                dispatch('getSavedReplies', { id: payload.mailbox_id});
            })
        }, 
        getWorkflows({ commit }, payload = null) {
            let payloadString =  payload ? '?' : '' ;
            for (const value in payload) {
                if (payloadString !== '?') {
                    payloadString = payloadString + '&'+ `${value}` + '='+`${payload[value]}`;
                } else {
                    payloadString = payloadString + `${value}` + '='+`${payload[value]}`;
                }
            }
            return ApiService.get(`workflows${payloadString}`).then((response) => {
                commit('setWorkflows', response.automatic);
                return response;
            })
        },
        selectMail({ commit }, payload) {
            commit('selectMail', payload);
        },
        ordersByTel({ commit }, payload) {
            return ApiService.get(`search-orders?keyword=${payload}`).then((response) => {
                commit('setOrdersByTel', response);
                return response;
            })
        }, 
        async getOriginalConversation({ commit }, payload) {
            return await ApiService.get(`fetch-original-thread/${payload}`).then((response) => {
                commit('setOriginalConversation', response.original_body);
                return response.original_body;
            });
        },
        resetOriginalConversation({ commit }) {
            commit('setOriginalConversation', '');
        },
        forwardMail({ commit }, payload) {
            commit('setForwardMail', payload);
        }, 
        changeStatus({ commit, dispatch }, payload) {
            ApiService.post('conversations/updatestatus', payload).then(() => {
                commit('setResetSelected', true);
                dispatch('refreshMails');
            });
        }
    },

    mutations: {
        setDepartUsers(state, name) {
            state.departUsers = name;
        },
        setUserName(state, name) {
            state.filterU = name;
        },
        setNewMails(state, payload) {
            state.newMails = payload._embedded.conversations;
            state.totalNewMails = payload.page.totalElements;
            state.loadNewMails = true;
        },
        setFilteredNewMails(state, payload) {
            state.filteredNewMails = payload;
        },
        setIsFilter(state, payload) {
            state.isFilter = payload;
        }, 
        setProcessMails(state, payload) {
            state.processMails = payload._embedded.conversations;
            state.totalProcessMails = payload.page.totalElements;
            state.loadInProcessMails = true;
        },
        setFilteredProcessMails(state, payload) {
            state.filteredProcessMails = payload;
            
        },
        setPendingMails(state, payload) {
            state.pendingMails = payload._embedded.conversations;
            state.totalPendingMails = payload.page.totalElements;
            state.loadPendingMails = true;
        },
        setFilteredPendingMails(state, payload) {
            state.filteredPendingMails = payload;
        },
        setFinalizedMails(state, payload) {
            state.finalizedMails = payload._embedded.conversations;
            state.totalFinalizedMails = payload.page.totalElements;
            state.loadFinalizedMails = true;
        },
        setFilteredFinalizedMails(state, payload) {
            state.filteredfinalizedMails = payload;
        },
        setMailsInSpam(state, payload) {
            state.mailsInSpam = payload;
        },
        setConversation(state, payload) {
            state.conversation = payload;
        },
        setAllConversations(state, payload) {
            state.conversations = payload;
        },
        setModalClose(state) {
            state.modal = false;
        },
        setModalOpen(state) {
            state.modal = true;
        },
        setAssigneeMail(state, { departId, userId }) {
            state.assigneeMail["mailbox_id"] = departId;
            state.assigneeMail["user_id"] = userId;
        },
        setAssigneeProcessMail(state, { selectedMail, currentUser } ) {
            state.assigneeProcessMail["conversations_ids"] = selectedMail;
            state.assigneeProcessMail["user_id"] = currentUser;
        },
        setAssigneeMailId(state, payload) {
            if( state.assigneeMail["conversations_ids"].indexOf(payload) === -1){
                state.assigneeMail["conversations_ids"].push(payload);
            }
        },

        selectMail(state , payload) {

                state.selectedMails.push(payload);
        },
        unselectMail(state , payload) {
            const i = state.selectedMails.map(item => item).indexOf(payload);
            state.selectedMails.splice(i, 1);
        },
        setSpamMailId(state, payload) {
            state.spamMail['conversations_ids'] = payload;
        }, 
        setInputSearchValue(state, payload) {
            state.inputSearchValue = payload;
        }, 
        setSearchAdvancedMails(state, payload) {
            state.searchEmails = payload;
        },
        setResetSelected(state, payload) {
            state.resetSelected = payload;
            state.selectedMails = [];
        }, 
        setConfig(state, payload) {
            state.config = payload;
        },
        setSavedReplies (state, payload) {
            state.savedReplies = payload;
        }, 
        setWorkflows(state, payload) {
            state.workflows = payload;
        },
        setOrdersByTel(state, payload) {
            state.ordersByTel = payload;
        }, 
        setOriginalConversation(state, payload) {
            state.originalConversation = payload;
        }, 
        setForwardMail(state, payload) {
            state.forwardMail = payload;
        }, 
        clearConversations(state) {
            state.newMails = [];
            state.pendingMails = [];
            state.finalizedMails = [];
            state.processMails = [];
        }
        
    }

}