import ApiService from "@/core/services/api.service";
import store from '@/core/services/store';

export default {
    namespaced: true,

    state() {
        return {
            convModal: false,
            configs: {},
            statuses: {
                'conversations_ids': '',
                'status': 'pending',
                'pending_status': '',
                'pending_date': '' 
            },
            convId: {
                'conversations_ids': '',
                'status': 'closed',
            }, 
            columns: []
        }
    },

    getters: {
        convModal: state => {
            return state.convModal;
        },
        configs: state => {
            return state.configs;
        },
        columns: state => {
            return state.columns;
        }, 
        statuses: state => {
            return state.statuses;
        },
        convId: state => {
            return state.convId;
        }
    },

    actions: {
        openConvModal({ commit, state }) {
            if(state.convModal === false) {
                commit('setOpenConvModal');
            }
        },
        closeConvModal({ commit, state }) {
            if (state.convModal === true) {
                commit('setCloseConvModal');
            }
        },
        getConfigs({ commit }) {
            ApiService.get('configs')
            .then((response) => {
                // console.log(response._embedded);
                commit('setConfigs', response._embedded)
            })
            // .catch((error) => console.log(error));
        },
        getConvId({ commit }, id) {
            commit('setStatuses', id)
            commit('setConvId', id)
        },
        getStatusModal({ commit },  status) {
            commit('setStatus', status);
        },
        getDate({ commit },  date) {
            commit('setDate', date);
        },
        getStatuses({ state }) {
            const statusesData = state.statuses;
            ApiService.post('conversations/updatestatus', statusesData);
        },
        updateStatus({ state }, payload) {
            return ApiService.post('conversations/updatestatus', payload).then(response => {
                if(response){
                    store.dispatch('mails/getConversation', payload.conversations_ids);
                }
                return response;
            });
        },
        async closeMail({}, payload) {
            await ApiService.post('conversations/updatestatus', payload)
        }

    },

    mutations: {
        setOpenConvModal(state) {
            state.convModal = true;
        },
        setCloseConvModal(state) {
            state.convModal = false;
        },
        setConfigs(state, payload) {
            // doar pentru pending statues sa fac pe coloane
            let columns = [];
            // let mid = Math.ceil(this.items.length / this.cols)
            let mid = 4;
            for (let col = 0; col < 3; col++) {
              columns.push(payload.pending_statuses.slice(col * mid, col * mid + mid));
            }
            state.columns = columns;

            state.configs = payload;
        },

        setStatuses(state, id) {
            state.statuses['conversations_ids'] = id;
        },
        setConvId(state, id) {
            state.convId['conversations_ids'] = id;
        },
        setStatus(state, status) {
            state.statuses['pending_status'] = status;
        },
        setDate(state, date) {
            state.statuses['pending_date'] = date;
        }
    }
}