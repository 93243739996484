import ApiService from "@/core/services/api.service";
import router from "@/router";

export default {
    namespaced: true,

    state() {
        return {
            alerts: [],
        }
    },

    getters: {
        getAlerts: state => {
            return state.alerts
        },
    },

    actions: {
        setNewNotification({ commit }, notification) {
          commit('setNewAlert', notification);
        },
        markAsRead({ commit, state }, notification_id){
          commit('markAsRead', notification_id);
        }
    },

    mutations: {
        setNewAlert(state, payload) {
          state.alerts.push(payload);
        },
        markAsRead(state, payload) {
          const i = state.alerts.map(item => item.conversation_id).indexOf(payload);
          state.alerts.splice(i, 1);
        }
        
    }

}