import ApiService from "@/core/services/api.service";

export default {
    namespaced: true,

    state() {
        return {
            notes: {
                "type": "note",
                'text': '',
                'user': null
            },
            convId: null
        }
    },

    getters: {
        threds: state => {
            return state.threads;
        }
    },

    actions: {
        getThreadsMesage({ commit }, inputText) {
            commit('setThreadsMesage', inputText);
        },
        getConvId({ commit }, userId) {
            commit('setConvId', userId);
        },
        getUserId({ commit }, userId) {
            commit('setUserId', userId);
        },
        getThreads({commit, state}) {
            // console.log(val, 'asta e valoarea')
            const threadsData = state.notes;
            const id = state.convId;
            ApiService.post('conversations/' + id + '/threads', threadsData)
            .then((response) => {
                commit('setThreads', response)
            })
        }
    },

    mutations: {
        setThreadsMesage(state, payload) {
            state.notes['text'] = payload;
        },
        setConvId(state, payload) {
            state.convId = payload;
        },
        setUserId(state, payload) {
            state.notes['user'] = payload;
        },
        setThreads(state, payload) {
            state.notes = payload;
        }
    }
}