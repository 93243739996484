import ApiService from "@/core/services/api.service";
import store from "@/core/services/store";

// action types
export const SPAM_WORKFLOW = "newWorkflow";
export const SPM_WRK = "spamworkflow";
export const EMAIL_WORKFLOW = "emailWorkflow";
export const GET_WORKFLOWS = "getworkflows";
export const DELETE_WORKFLOW = "deleteworkflow";
export const CLIENT_DIALOG = "clientDialog";

// mutation types
export const SET_ERROR = "SET_ERROR";

const state = {
    workflow: [],
    workflow_exists: [],
    clientDialog: false,
};

const getters = {
    workflow(state) {
        return state.workflow;
    },
    clientDialog(state) {
        return state.clientDialog;
    },
};

const actions = {
    async [SPM_WRK](context, data) {
        let dataForm = {
            name: "Mutare in spam -> " + data.conversation.customer.email,
            apply_to_prev: true,
            active: true,
            type: "automatic",
            conditions: [
                [
                    {
                        type: "customer_email",
                        operator: "equal",
                        value: data.conversation.customer.email,
                    },
                ],
            ],
            actions: [
                [
                    {
                        type: "status",
                        value: "4",
                        // TODO: trebuie schimbat si pus  numele statusului nu id-ul
                    },
                ],
            ],
        };

        let spamData = {
            conversations_ids: [data.conversation.id],
            status: "spam"
        };

        if (data.addRuleForAll) {
            dataForm.mailboxes_ids = [];
            data.departments.map((depart) => {
                dataForm.mailboxes_ids.push(depart.id);
            });
        }

        return await ApiService.post(
            "mailboxes/" + data.conversation.mailboxId + "/workflows",
            dataForm
        ).then(response => {
            if (response.success) {
                ApiService.post('conversations/updatestatus', spamData)
                .then((response) => {
                    if(response.msg && response.msg == 'Status updated'){

                        store.commit("alerts/setNewAlert", {
                            message_header: "Status conversatie modificat",
                            message_content: "Conversatia a fost adaugata in SPAM",
                            variant: "info",
                        });
                    }
                    store.dispatch("mails/getConversation", data.conversation.id);
                })                
            }
           return response;
        })
    },
    [SPAM_WORKFLOW](context, data) {
        store.dispatch('mails/getNewMails').then(res => { 
            return res._embedded.conversation; 
        });
        // console.log(context.getters["mails/newMails"]);

        var conversations = [];
        var workflow_exists = [];
        // console.log('mail conversations', context.getters["mails/conversations"]);
        context.getters["mails/newMails"].filter((el) => {
            return data.conversations_ids.map((convId) => {
                if (convId === el.id) {
                    conversations.push(el);
                }
            });
        });


        // console.log('conversatie de pus in spam', conversations);
        if (conversations.length == 0) {
            conversations.push(context.getters["mails/conversation"]);
        }

        conversations.forEach(function (conversation) {
            let dataForm = {
                name: "Mutare in spam -> " + conversation.customer.email,
                apply_to_prev: true,
                active: true,
                type: "automatic",
                conditions: [
                    [
                        {
                            type: "customer_email",
                            operator: "equal",
                            value: conversation.customer.email,
                        },
                    ],
                ],
                actions: [
                    [
                        {
                            type: "status",
                            value: "4",
                            // TODO: trebuie schimbat si pus  numele statusului nu id-ul
                        },
                    ],
                ],
            };

            // console.log('data form', dataForm);

            if (!data.addRuleForAll) {
                const response = ApiService.post(
                    "mailboxes/" + conversation.mailboxId + "/workflows",
                    dataForm
                ).then((response) => {
                    // console.log(response);
                    let errorString = "";
                    if (response.errors) {
                        errorString += response.message;
                        if (response.errors.workflow_exists) {
                            response.errors.workflow_exists.forEach(element => { 
                                workflow_exists.push(element);
                            });
                            let conver = {
                                conv_id: conversation.id,
                                data: workflow_exists
                            }
                            context.state.workflow_exists.push(conver);

                        } else {
                            for (const property in response.errors) {
                                errorString += ". " + response.errors[property];
                            }
                        }
                        store.commit("alerts/setNewAlert", {
                            message_header: "Adaugare regula",
                            message_content: "Eroare " + errorString,
                            variant: "danger",
                        });
                    } else {
                        store.commit("alerts/setNewAlert", {
                            message_header: "Adaugare regula",
                            message_content:
                                'Regula "Mutare in spam -> ' +
                                conversation.customer.email +
                                '" a fost adaugata cu succes in departamentul ' +
                                conversation.departament,
                            variant: "success",
                        });
                    }

                }).then(() => {
                    return workflow_exists;
                });

                // console.log('raspuns', response);
                // console.log('context workflow exists', context.state.workflow_exists);
            } 
            else 
            {

                return Promise.all(data.departments.map((depart) => {
                    ApiService.post(
                        "mailboxes/" + depart.id + "/workflows",
                        dataForm
                    ).then((response) => {
                        let errorString = "";
                        if (response.errors) {
                            errorString += response.message;
                            if (response.errors.workflow_exists) {
                                response.errors.workflow_exists.forEach(element => { 
                                    workflow_exists.push(element);
                                });
                            } else {
                                for (const property in response.errors) {
                                    errorString += ". " + response.errors[property];
                                }
                            }
                        } else {
                            store.commit("alerts/setNewAlert", {
                                message_header: "Adaugare regula",
                                message_content:
                                    'Regula "Mutare in spam -> ' +
                                    conversation.customer.email +
                                    '" a fost adaugata cu succes in departamentul' +
                                    depart.name,
                                variant: "success",
                            });
                        }
                    });
                })).then(() => {
                    return workflow_exists;
                });
            }
        });
    },

    [EMAIL_WORKFLOW](context, data) {
        let actions = [];
        let userString = "";

        if (data.userId) {
            actions.push([
                {
                    type: "assign",
                    value: data.userId,
                },
            ]);
            userString = " si asignare la " + data.userName;
        }

        if (data.departId !== data.fromDepartId) {
            actions.push([
                {
                    type: "move",
                    value: data.departId,
                },
            ]);
        }

        let dataForm = {
            name:
                "Mutare " +
                data.email +
                " in departamentul " +
                data.departName +
                userString,
            apply_to_prev: data.apply_to_prev,
            active: true,
            type: "automatic",
            conditions: [
                [
                    {
                        type: "customer_email",
                        operator: data.operator,
                        value: data.email,
                    },
                ],
            ],
            actions: actions,

        };

        if (data.mailboxes_ids && data.mailboxes_ids.length) {
            dataForm.mailboxes_ids = data.mailboxes_ids
        }

        return ApiService.post(
            "mailboxes/" + data.fromDepartId + "/workflows",
            dataForm
        ).then((response) => {
            return response;
        });
    },

    [GET_WORKFLOWS]({ context }, payload) {
        let payloadString = '?';
        for (const value in payload.params) {
            if (payloadString !== '?') {
                payloadString = payloadString + '&'+ `${value}` + '='+`${payload.params[value]}`;
            } else {
                payloadString = payloadString + `${value}` + '='+`${payload.params[value]}`;
            }
        }
        return ApiService.get("mailboxes/" + payload.mailboxId + "/workflows" + payloadString).then(
            (response) => {
                // console.log(response);
                return response;
            }
        );
    },
    [DELETE_WORKFLOW]({ context }, workflowId, data = null) {
        if(Array.isArray(workflowId)) {
            const workflows_ids = workflowId;
            ApiService.post("workflows/multi-delete", { workflows_ids }).then((response) => {
                if (response.success) {
                    store.commit("alerts/setNewAlert", {
                        message_header: "Stergere toate regulile",
                        message_content: "Regulile au fost sterse cu succes",
                        variant: "success",
                    });
                } else {
                    store.commit("alerts/setNewAlert", {
                        message_header: "Stergere toate regulile",
                        message_content: "Regulile nu au putut fi sterse",
                        variant: "danger",
                    });
                }
            });
        } else {
            ApiService.post("workflows/" + workflowId + "/delete").then((response) => {
                // TODO: mai trebuie customizat mesajul de stergere regula, pt ca sa se stie ce regula a fost stearsa
                if (response.success) {
                    store.commit("alerts/setNewAlert", {
                        message_header: "Stergere regula",
                        message_content: "Regula a fost stearsa cu succes",
                        variant: "success",
                    });
                } else {
                    store.commit("alerts/setNewAlert", {
                        message_header: "Stergere regula",
                        message_content: "Regula nu a putut fi stearsa",
                        variant: "danger",
                    });
                }
            });
        }
    },

    [CLIENT_DIALOG]({ commit }, payload) {
        commit("setClientDialog", payload);
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },

    setClientDialog(state, payload) {
        state.clientDialog = payload;
    },
};

export default {
    // namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
