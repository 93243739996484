import ApiService from "@/core/services/api.service";
import router from "@/router";

export default {
    namespaced: true,

    state() {
        return {
            messages: [],
        }
    },

    getters: {
        messages: state => {
            return state.messages
        },
    },

    actions: {
        getMessages({ commit }, payload = null) {

            let payloadString =  payload ? '?' : '';
            for (const value in payload) {
                if (payloadString !== '?') {
                    payloadString = payloadString + '&'+ `${value}` + '='+`${payload[value]}`;
                } else {
                    payloadString = payloadString + `${value}` + '='+`${payload[value]}`;
                }
            }
        
            return ApiService.get(`notify-message${payloadString}`).then((response) => {
                // console.log(response);
                commit('setMessages', response);
                return response;
            })          

        },
        sendMessage({ dispatch }, payload) {
            ApiService.post('notify-message', payload).then(() => {
                dispatch('getMessages');
            })
        }, 
        markAsRead({}, payload) {
            ApiService.post(`mark-notified-user-message-as-read/${payload}`).then(() => {
                // console.log('mark as read');
            })
        }, 
        markAsConfirm({ dispatch }, payload) {
            ApiService.post(`mark-notified-user-message-as-agreed/${payload}`).then(() => {
                // console.log('mark as aggred');
                dispatch('getMessages');
            })
        }, 
        deleteMessage({dispatch}, payload) {
            ApiService.delete(`notify-message/${payload}`).then(() => {
                dispatch('getMessages');
            })
        }
    },

    mutations: {
        setMessages(state, payload) {
          state.messages = payload;
        },
        
    }

}