import Vue from "vue";
// import axios from "axios";
// import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  baseURL: '',
  init() {
    // Vue.use(VueAxios, axios);
    // Vue.axios.defaults.baseURL = "http://sharedmailapi.pefoc.ro/api/";
    // Vue.axios.defaults.headers.common[
    //   "X-FreeScout-API-Key"
    // ] = `51351ff4b2bd5456fef3f42380b17250`;
    this.baseURL = "https://sharedmailapi.pefoc.ro/api/v1";
    this.setHeader();
  },


  headers: {
    "X-FreeScout-API-Key": "51351ff4b2bd5456fef3f42380b17250",
    "Accept": 'application/json',
    "Content-Type": 'application/json',
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    // Vue.axios.defaults.headers.common[
    // "Authorization"
    // ] = `Token ${JwtService.getToken()}`;

    // Vue.axios.defaults.headers.common[
    // "X-FreeScout-API-Key"
    // ] = `51351ff4b2bd5456fef3f42380b17250`;
    // this.headers['X-FreeScout-API-Key'] = `51351ff4b2bd5456fef3f42380b17250`;
    this.headers['x-Authorization'] = `Bearer ${JwtService.getToken()}`;
  },

  async query(resource, params) {

    var formdata = new FormData();

    for (const [key, value] of Object.entries(params)) {
      // console.log(`${key}: ${value}`);
      formdata.append(key, value);
    }

    var requestOptions = {
      method: 'GET',
      headers: this.headers,
      body: formdata
    };
    let result = await fetch(`${this.baseURL}/${resource}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        return result;
      })
      // .catch(error => console.log('error', error));
    return await result;
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  async get(resource) {

    var requestOptions = {
      method: 'GET',
      headers: { 'x-Authorization': `Bearer ${JwtService.getToken()}` },
    };
    let result = await fetch(`${this.baseURL}/${resource}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        return result;
      })
      // .catch(error => console.log(new Error(`[KT] ApiService ${error}`)));
    return await result;
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  async post(resource, params) {

    var requestOptions = {
      method: 'POST',
      headers: {...this.headers},
      body: JSON.stringify(params)
    };
    
    let result = await fetch(`${this.baseURL}/${resource}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        return result;
      })
      // .catch(error => console.log('error', error));
    return await result;

  },

    /**
   * Set the PUT HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
    async put(resource, params) {

      var requestOptions = {
        method: 'PUT',
        headers:{ 'x-Authorization': `Bearer ${JwtService.getToken()}`,       'Accept': 'application/json',
        'Content-Type': 'application/json' },
        body: JSON.stringify(params)
      };
      let result = await fetch(`${this.baseURL}/${resource}`, requestOptions)
        // .then(response => response.json())
        .then(result => {
          return result;
        })
        // .catch(error => console.log('error', error));
      return await result;
  
    },

    /**
   * Set the POST JSON HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
     async postJSON(resource, params) {
      
      var requestOptions = {
        method: 'POST',
        headers:{ 'x-Authorization': `Bearer ${JwtService.getToken()}`,       'Accept': 'application/json',
        'Content-Type': 'application/json' },
        body: JSON.stringify(params)
      };
      let result = await fetch(`${this.baseURL}/${resource}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          return result;
        })
        // .catch(error => console.log('error', error));
      return await result;
  
    },

    
  /**
   * Set the POST HTTP request
   * @param resource
   * @param file
   * @returns {*}
   */
  async postAttachments(resource, file) {

    var formData = new FormData();
    formData.append('file',file);

    let requestOptions = {
      method: 'POST',
      headers: {...this.headers},
      body: formData
    };

    // If is attachments let content-type set itself as boundary
    delete requestOptions.headers['Content-Type'];
    
    let result = await fetch(`${this.baseURL}/${resource}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        return result;
      })
      // .catch(error => console.log('error', error));
    return await result;

  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  // put(resource, params) {
  //   return Vue.axios.put(`${resource}`, params);
  // },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
   async delete(resource) {

    var requestOptions = {
      method: 'DELETE',
      headers: this.headers,
    };
    let result = await fetch(`${this.baseURL}/${resource}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        return result;
      })
      // .catch(error => console.log('error', error));
    return await result;

  }
};

export default ApiService;
