import ApiService from "@/core/services/api.service";
import store from "@/core/services/store";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";

export default {
    namespaced: true,

    state() {
        return {
            users: [],
            allUsers: false,
            userStatus: {
                "status": ''
            },
            userId: '',
            eachUser: [],
            currentUser: false
        }
    },

    getters: {
        users: state => {
            return state.users;
        },
        eachUser: state => {
            return state.eachUser;
        },
        allUsers: state => {
            return state.allUsers;
        },
        userStatus: state => {
            return state.userStatus;
        },
        userId: state => {
            return state.userId;
        },
        currentUser: state => {
            return state.currentUser;
        }
    },

    actions: {
        getUsers({ commit }) {
            ApiService.get('users?sortField=firstName&sortOrder=asc')
                .then((response) => {
                    commit('setUsers', response._embedded.users)
                    const users = response._embedded.users;
                    let eachUser = [{ value: null, text: "Niciun agent selectat"}];
                    users.forEach((user) => {
                        eachUser.push({ value: user.id, text: user.firstName })
                    })
                    commit('setEachUser', eachUser)
                })
                // .catch((error) => console.log(error));
        },
        getUsersData({ commit }, userId) {
            return ApiService.get('users/' + userId)
                .then((response) => {
                    commit('setCurrentUser', response)
                })
                .then((response) => {
                    return response;
                })
        },
        getAllUsers({ commit }, value) {
            commit('setAllUsers', value);
        },
        getUserStatus({ commit }, {userId, status}) {
            commit('setUserStatus', status)
            commit('setUserId', userId)
        },
        sendUserStatus({ dispatch },{userId, status}) {
            var statusUser= {
                "status": status
            };
            return ApiService.post(`users/${userId}`, statusUser)
                .then((response) => {
                    store.dispatch('departments/getDepartments');
                    store.dispatch('departments/getAgents');
                    store.dispatch(VERIFY_AUTH);
                    return response;
                })
        },
        editUser({ dispatch },{userId, data}) {
            return ApiService.post(`users/${userId}`, data)
                .then((response) => {
                    store.dispatch('departments/getDepartments');
                    store.dispatch('departments/getAgents');
                    store.dispatch(VERIFY_AUTH);

                    if(response.msg == 'User updated'){
                        store.commit('alerts/setNewAlert', {
                            message_header: 'Editare utilizator',
                            message_content: "Modificarile pentru utilizatorul : " + data.first_name + " " + data.last_name + " au fost salvate",
                            variant: 'success'
                        });
                    } else {
                        store.commit('alerts/setNewAlert', {
                            message_header: 'Editare utilizator',
                            message_content: "Modificarile nu au putu fi salvate. Incercati din nou!",
                            variant: 'danger'
                        });
                    }
                    dispatch('getUsersData', userId);
                    return response;
                })
        },
        editPass({ dispatch },{userId, data}) {
            return ApiService.post(`users/${userId}/update-password`, data)
                .then((response) => {
                    store.dispatch('departments/getDepartments');
                    store.dispatch('departments/getAgents');
                    store.dispatch(VERIFY_AUTH);
                    if(response.status == "Success"){
                        store.commit('alerts/setNewAlert', {
                            message_header: 'Modificare parola',
                            message_content: "Parola pentru : " + data.firstName + " " + data.lastName + " a fost modificata",
                            variant: 'success'
                        });
                    } else {
                        store.commit('alerts/setNewAlert', {
                            message_header: 'Modificare parola',
                            message_content: "Parola nu a putut fi modificata: " + response.message[0],
                            variant: 'danger'
                        });
                    }
                    dispatch('getUsersData', userId);
                    return response;
                })
        },
        addUser({ dispatch },{data}) {
            return ApiService.post(`users`, data)
                .then((response) => {
                    if(response.message){
                        var message_content = "S-a produs o eroare. Va rugam incercati din nou, si verificati ca toate campurile sa fie completate! ";
                        if(response._embedded.errors.length > 0){
                            message_content = "Utilizatorul nu a putut fi creat: " + response._embedded.errors[0].message;
                        } 
                        store.commit('alerts/setNewAlert', {
                            message_header: 'Creare utilizator utilizator',
                            message_content: message_content,
                            variant: 'danger'
                        });
                    } else {
                        store.dispatch('departments/getDepartments');
                        store.dispatch('departments/getAgents');
                        store.dispatch(VERIFY_AUTH);
                        store.commit('alerts/setNewAlert', {
                            message_header: 'Creare utilizator',
                            message_content: "S-a creat utilizatorul : " + data.firstName + " " + data.lastName,
                            variant: 'success'
                        });
                    }
                    // dispatch('getUsersData', userId);
                    return response;
                })
        },
        deleteUser({ dispatch },{userId, data}) {
            return ApiService.delete(`users/${userId}`)
                .then((response) => {
                    if(response.status == 'success'){
                        store.dispatch('departments/getDepartments');
                        store.dispatch('departments/getAgents');
                        store.dispatch(VERIFY_AUTH);
                        store.commit('alerts/setNewAlert', {
                            message_header: 'Stergere utilizator',
                            message_content: "S-a sters utilizatorul : " + data.firstName + " " + data.lastName,
                            variant: 'success'
                        });
                    } else {
                        var message_content = "S-a produs o eroare. Va rugam incercati din nou! ";
                        if(response._embedded.errors.length > 0){
                            message_content = "Utilizatorul nu a putut fi sters: " + response._embedded.errors[0].message;
                        } 
                        store.commit('alerts/setNewAlert', {
                            message_header: 'Stergere utilizator',
                            message_content: message_content,
                            variant: 'danger'
                        });
                    }
                    return response;
                })
        }
    },

    mutations: {
        setUsers(state, payload) {
            state.users = payload;
        },
        setEachUser(state, payload) {
            state.eachUser = payload;
        },
        setAllUsers(state, payload) {
            state.allUsers = payload;
        },
        setUserStatus(state, payload) {
            state.userStatus["status"] = payload;
        },
        setUserId(state, payload) {
            state.userId = payload;
        },
        setCurrentUser(state, payload) {
            state.currentUser = payload;
        }
    }

}