import ApiService from "@/core/services/api.service";
import router from "@/router";
import store from "@/core/services/store";

export default {
    namespaced: true,

    state() {
        return {
            notifications: [],
            page: 1,
            unread_count: 0,
            agentNotifications: []
        }
    },

    getters: {
        getNotifications: state => {
            return state.notifications
        },
        getPage: state => {
            return state.page
        },
        getUnreadCount: state => {
            return state.unread_count
        },
        getAgentNotifications: state => {
          return state.agentNotifications
        }
    },

    actions: {
        getNewNotifications({ commit, state }) {
            ApiService.get(`notifications?page=${state.page}`).then((response) => {
              commit('setNotifications', response.data);
              commit('setUnreadCount', response.unread_count);
              commit('setAgentNotifications', response.notify_messages);
            })
          },
          getNewestNotifications({ commit }) {
            ApiService.get(`notifications?page=1`).then((response) => {
              // console.log(response.notify_messages);

              commit('setNewestNotifications', response.data);
              commit('setUnreadCount', response.unread_count);
              commit('setAgentNotifications', response.notify_messages)

            })
          },
          incrementPage({ commit}){
            commit('incrementPage');
          },
          markAsRead({commit}, notification){
            commit('markAsRead', notification);
          }, 
          markAllAsRead({dispatch}) {
            // console.log('ajunge aici');
            ApiService.get('mark-notifications-as-read').then(() => {
              dispatch('getNewestNotifications');
              // console.log(response);
            });
          }
    },

    mutations: {
    
      markAsRead(state, payload) {
        const i = state.notifications.map(item => item.conversation_id).indexOf(payload);
        state.notifications[i].is_read = true;
        state.unread_count = state.unread_count - 1;
      },
        setNotifications(state, payload) {
          if(payload){
            payload.forEach(element => {
              state.notifications.push(element);
            });
          }
        },
        setNewestNotifications(state, payload) {
          if(payload){
            var newNotifications = []
            var lastNotificationDate = state.notifications[0].created_at;
            payload.forEach(element => {
              if(element.created_at > lastNotificationDate){
                newNotifications.unshift(element);
              }

            });
            if(state.page == 1){
              state.notifications =  payload;
            }
            if(Object.keys(newNotifications).length !== 0){
              newNotifications.forEach(element => {
                store.commit('alerts/setNewAlert', element);
              });
            }
          }
        },
        setUnreadCount(state, payload) {
            state.unread_count = payload;
        },
        incrementPage(state) {
            state.page = state.page + 1;
        },
        setAgentNotifications(state , payload) {
          state.agentNotifications = payload;
        }
        
    }

}