import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import mails from "./modules/mails";
import departments from "./modules/departments";
import users from "./modules/users";
import buttons from "./modules/buttons";
import threads from "./modules/threads";
import notifications from "./modules/notifications";
import alerts from "./modules/alerts";
import workflow from "./modules/workflow";
import messages from "./modules/messages";
import customers from "./modules/customers";

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    mails,
    departments,
    users,
    buttons,
    threads,
    notifications,
    alerts,
    workflow, 
    messages,
    customers 
  }
});
