// import ApiService from "@/core/services/api.service";

import ApiService from "../../api.service";

export default {
    namespaced: true,

    state() {
        return {
            departments: [],
            allDepartments: [],
            eachDepartments: [],
            departmentUsers: [], 
            departament: {},
            agents: [], 
            showUsers: true,
            userDepartments: []
        }
    },

    getters: {
        departments: state => {
            return state.departments;
        },
        agents: state => {
            return state.agents;
        },
        eachDepartments: state => {
            return state.eachDepartments;
        },
        allDepartments: state => {
            return state.allDepartments;
        },
        departmentUsers: state => {
            return state.departmentUsers;
        }, 
        department: state => {
            return state.departament;
        }, 
        showUsers: state => {
            return state.showUsers;
        }, 
        userDepartments: state => {
            return state.userDepartments;
        }
    },

    actions: {
        async getPasareDepartments({ commit }) {
            return await ApiService.get('mailboxes').then((response) => {
                const departments = response._embedded.mailboxes;
                let eachDepartmentOptions = [{ value: null, text: "Alege un departament*", disabled: true }];
                departments.forEach((dep) => {
                    eachDepartmentOptions.push({ value: dep.id, text: dep.name })
                })
                commit('setEachDepartments', eachDepartmentOptions)
                commit('setAllDepartments', departments)
            });
        },
        async getPasareUsers({ commit, rootGetters }, department_id) {
            ApiService.get(`mailboxes?departmentId=${department_id}`).then((response) => {                
                let userNames = [{ value: null, text: "Tot departamentul" }];
                response._embedded.mailboxes[0].users.forEach((user) => {
                    if(user.firstName != 'Admin'  || rootGetters.user.firstName == "Admin"){
                        userNames.push({ value: user.id, text: user.firstName + " " + user.lastName, color:user.status_color, count:user.conversationsCount, status:user.status  });
                    }
                })
    
                commit('setDepartmentUsers', userNames)
            })

        },
        async getDepartments({ commit, dispatch, rootGetters }, departId = null) {
            // console.log(rootGetters);
            return await ApiService.get(`mailboxes?userId=${rootGetters.user.id}`)
                .then((response) => {
                    commit('setDepartments', response._embedded.mailboxes)
                    if (!departId) {
                        dispatch('getAgents');
                    }

                    return response._embedded.mailboxes
                })
                // .catch((error) => console.log(error));
        },
        async getDepartmentsForUser({ commit}, deuserId) {
            return await ApiService.get(`mailboxes?userId=${deuserId}`)
                .then((response) => {
                    commit('setUserDepartments', response._embedded.mailboxes)
                    return response;
                })
        },
        resetDepartments({ commit }) {
            commit('setDepartments', {})
        },
        async getDepartment({ commit, state, dispatch, rootGetters }, departament_id = 0) {

            const department = await state.departments.find(elem => {
                return elem.id === departament_id || elem.name === departament_id
            })
            if (departament_id) {
                commit('setDepartment', department);
                let agents = [];
                department.users.map(user => {
                    if(user.firstName != 'Admin'){
                        agents.push(user);
                    }
                })
                agents = agents.sort((x,y) => {
                    return x.id === rootGetters.user.id ? -1 : y.id === rootGetters.user.id ? 1 : 0
                })
                commit('setAgents', agents);
            } else {
                
                commit('setDepartment', {});
                dispatch('getAgents');
            }

        },
        resetDepartment({ commit }) {
            commit('setDepartment', {})
        }, 
        getAgents({ commit, state, dispatch, rootGetters }) {
            let agents = [];
            if(state.departments){
            state.departments.map(elem => {
                elem.users.map(user => {
                    if(user.firstName != 'Admin'){
                        agents.push(user);
                    }
                })
            });
            agents = Array.from(new Set(agents.map(a => a.id)))
            .map(id => {
              return agents.find(a => a.id === id)
            })

            //move current user to the front
            agents.sort((x,y) => {
                return x.id === rootGetters.user.id ? -1 : y.id === rootGetters.user.id ? 1 : 0
            })           

            // setTimeout(() => {
                commit('setAgents', agents)
            // }, 500);
            } else {
                setTimeout(() => {
                    dispatch('getAgents');
                }, 300);
            }
        },
        getDepartmentName({ state }, departmentID) {
            let departmentName = '';
            if(state.departments){
                state.departments.filter((el) => {
                    if(el.id == departmentID){
                        departmentName = el.name;
                    }
              });
            }
            return departmentName;
        }, 
        getAgentName({ state }, userId) {
            let agentName = '';
            if(state.agents){
                state.agents.filter((el) => {
                    if(el.id == userId){
                        agentName = el.nickName;
                    }
              });
        }
            return agentName;
        },
    },

    mutations: {
        setDepartment(state, payload) {
            state.departament = payload;
        },
        setDepartments(state, payload) {
            state.departments = payload;
        },
        setEachDepartments(state, payload) {
            state.eachDepartments = payload;
        },
        setAllDepartments(state, payload) {
            state.allDepartments = payload;
        },
        setDepartmentUsers(state, payload) {
            state.departmentUsers = payload;
        }, 
        setAgents(state, payload) {
            state.agents = payload;
        }, 
        showUsers(state, payload) {
            state.showUsers = payload;
        }, 
        setUserDepartments(state, payload) {
            state.userDepartments = payload;
        }
    }
}