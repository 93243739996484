import KTUtil from "@/assets/js/components/util";

const GlobalHelper = {
    install(Vue) {
        /**
         * Add Spinner To a buton
         * @param {Object} btn - element
         * @param {number} timeout - timout untill kill
         */
        Vue.prototype.addSpinner = (btn, timeout = null) => {
            KTUtil.btnWait(btn, "spinner spinner-right spinner-white pr-15");
            if (timeout) {
                setTimeout(() => {
                    KTUtil.btnRelease(btn);
                }, timeout);
            }
        },
        //Kill spinner for button
        Vue.prototype.killSpinner = (btn) => {
            KTUtil.btnRelease(btn);
        }
    }
};

export default GlobalHelper;